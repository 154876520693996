import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import styled from 'styled-components';

/* eslint-disable no-undef */

const Wrapper = styled.div`
	width: 100%;
	font-family: 'Nunito Sans',arial;
	font-size: 14px;
	user-select: none;
  .cont {
    position: relative;
    padding-top: ${({ withLabel }) => withLabel ? '20px' : '10px'};
  }
  .touch {
    width: ${({ width }) => width ? `${width}px` : '12px'};
    height: 12px;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: ${({ withLabel }) => withLabel ? '15px' : '5px'};
    cursor: pointer;
    left: 0px;
  }
  .arrow {
    width: ${({ width }) => width ? `${width}px` : '12px'};
    height: 10px;
    position: absolute;
    top: 5px;
  }
  .value {
    position: absolute;
    top: -16px;
    cursor: pointer;
    left: 0;
    padding: 5px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  .min-value {
    position: absolute;
    top: -8px;
    cursor: pointer;
    left: 0;
    color: ${({ color }) => color ? color : 'var(--color-main)'};
    text-align: left;
  }
  .max-value {
    position: absolute;
    top: -8px;
    cursor: pointer;
    right: 0;
    color: ${({ color }) => color ? color : 'var(--color-main)'};
    text-align: right;
  }
  .line {
    border-radius: 10%;
    height: 6px;
    position: absolute;
    top: ${({ withLabel }) => withLabel ? '17px' : '7px'};
    left: 0px; width: 100%;
  }
  .line-inactive {
    background: ${({ inactiveColor }) => inactiveColor ? inactiveColor : '#888'};
    border-radius: 10%;
    height: 3px;
    position: absolute;
    top: ${({ withLabel }) => withLabel ? '20px' : '10px'};
    left: 0px; width: 100%;
  }
  .range-label {
    padding-top: 1em;
    p {
      display: inline-block;
      color: #595959;
      font-weight: bold;
      font-size: .85em;
      &:first-child {
        float: left;
      }
      &:last-child {
        float: right;
      }
    }
  }
`;

class RangerInput extends React.Component {

  state = { isDragging: false, posX: 0 }
  maxValue = null;

  componentWillMount() {
  	const { min, defaultValue, value, onChange} = this.props;
  	if (value === 0) {
			setTimeout(() => onChange(min), 500);
		}
		if (defaultValue) {
			setTimeout(() => onChange(defaultValue), 500);
		}
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isDragging && !prevState.isDragging) {
			document.addEventListener('touchmove', this.onMove);
			document.addEventListener('touchend', this.onStop);
      document.addEventListener('mousemove', this.onMove);
      document.addEventListener('mouseup', this.onStop);
    } else if (!this.state.isDragging && prevState.isDragging) {
      document.removeEventListener('mousemove', this.onMove);
      document.removeEventListener('touchmove', this.onMove);
      document.removeEventListener('mouseup', this.onStop);
      document.removeEventListener('touchend', this.onStop);
    }
    if (this.touchControl && this.touchControl.offsetParent) {
      const defaultWidth = parseInt(this.props.width, 10) || 12;
      this.maxValue = Math.floor(this.touchControl.offsetParent.getBoundingClientRect().width - defaultWidth);
    }
  }
  getRelPosX = (width) => {
    if (!width) throw new Error('width is not defined for RangerInput.getRelPosX');

    // const defaultWidth = parseInt(this.props.width, 10) || 12;
    const minValue = this.props.min;
    const maxValue = this.props.max;
    const value = this.props.value ? this.props.value : minValue;
    const pos = (value - minValue) / ((maxValue - minValue) / 100);
    // console.info('getRelPosX', minValue, maxValue, value, pos, width);
    return (pos * width) / 100.0;
  }
  onStart = (e, width) => {
    //if (e.button !== 0 || e.touches.length !== 1) return;
    const sliderPos = this.touchControl.getBoundingClientRect();
    this.setState({
      isDragging: true,
      posX: sliderPos.left - this.getRelPosX( width )
    });
    e.stopPropagation();
    e.preventDefault();
  }
  onMove = (e) => {
    const defaultWidth = parseInt(this.props.width, 10) || 12;
    const pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
    const maxPosX = this.touchControl.offsetParent.getBoundingClientRect().width - defaultWidth;
    const val = Math.max((pageX - (defaultWidth / 2)) - this.state.posX, 0);
    const relPosX = Math.min(val, maxPosX);
    this.calculate(relPosX);
  }
  onStop = (e) => {
    if (!this.state || !this.state.isDragging) return;
    this.setState({ isDragging: false });
    e.stopPropagation();
    e.preventDefault();
  }
  calculate = (relPosX) => {
    const val = Math.ceil((Math.floor(relPosX) * 100) / this.maxValue);
    const currentValue = this.props.type === 'float' ?((relPosX * 100) / this.maxValue).toFixed(2) : val;
    const rangeValue = this.calculateRangeValue(currentValue);
    this.props.onChange(rangeValue);
  }
  calculateRangeValue = (value)  => {
    const minValue = this.props.min;
    const maxValue = this.props.max;
    const koef = (maxValue - minValue) / 100;
    // const decValue = (minValue + (value * koef)).toFixed(1);
    const div = this.props.div || 4;
    const precision = (div >= 4) ? 2 : 1;
    const decValue = ( Math.round(div * (minValue + (value * koef))) / div).toFixed(precision);
    return this.props.type === 'float' ? decValue : minValue + Math.round(value * koef);
  }
  render() {
    const value = this.props.value ? this.props.value : this.props.min;
    let valueToDisplay = value.toString().replace(/\.0+$/, '');

    if(value === 90)
    {
      valueToDisplay = "90+"
    }

    const valueWidth = valueToDisplay.length * 8 + 20
    // console.info('RangerInput', value, valueToDisplay, valueWidth, this.getRelPosX());
    /* eslint-disable eqeqeq */
    return (
      <Wrapper {...this.props} className="ranger-wrapper">
        <ContainerDimensions>
          {({ width }) => {
            if (!width) return false;
            return (
              <div key={1} className='cont'>
                {/*this.props.withLabel && (this.props.min != value && this.getRelPosX(width) > 70 ) ? (
                  <div className="min-value">
                    <span style={{ color: '#bbb' }}>min:&nbsp;</span>
                    { this.props.prefix && <span>{this.props.prefix}</span> }
                    <span>{this.props.min}</span>
                    { this.props.suffix && <span>{this.props.suffix}</span> }
                  </div>
                ) : false*}
                {/*this.props.withLabel && (this.props.max != value && this.getRelPosX(width) < width - 70) ? (
                  <div className="max-value">
                    <span style={{ color: '#bbb' }}>max:&nbsp;</span>
                    { this.props.prefix && <span>{this.props.prefix}</span> }
                    <span>{this.props.max}</span>
                    { this.props.suffix && <span>{this.props.suffix}</span> }
                  </div>
                ) : false*/}
                {this.props.withLabel ? (
                  <div
                    className="value"
                    onMouseDown={e => this.onStart(e, width)}
                    onTouchStart={e => this.onStart(e, width)}
                    ref={(touchControl) => { this.touchControl = touchControl; this.width = width; }}
                    style={{ left: this.getRelPosX(width) - valueWidth / 2 }}
                  >
                    { this.props.prefix && <span>{this.props.prefix}</span> }
                    <span>{valueToDisplay}</span>
                    { this.props.suffix && <span>{this.props.suffix}</span> }
                  </div>
                ) : false }
                <div className='line-inactive'><span>&nbsp;</span></div>
                <div className='line' style={{ width: this.getRelPosX(width) }}><span>&nbsp;</span></div>

                <div className="arrow" style={{ left: (this.getRelPosX(width) || 0) - 8 }}>
                  <svg style={{ width: 16, height: 8}} viewBox='0 0 16 8'>
                    <path d="M 0,0 L16,0 L8,8 Z" />
                  </svg>
                </div>
              </div>
            );
          }}
        </ContainerDimensions>
      </Wrapper>
    );
  }
}

export default RangerInput;
