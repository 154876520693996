import { ILtvConfig, ILevelTitles } from './types';

// more2life
const titlesEquityRelease: ILevelTitles = {
    titles: [
        { title: 'Minimum Loan', subtitle: 'facility*', hint: '' },
        { title: 'Average Loan', subtitle: 'on standard terms', hint: '' },
        {
            title: 'Maximum Loan',
            subtitle: 'on standard terms',
            hint: ''
        }
    ],
    header: 'Equity Release Calculator',
    detailsTitle: 'Homeowner Details',
    ageTitle: 'Age (youngest if couple)',
    valueTitle: 'Value of Home',
    intro: `Enter basic information below to find out how much cash you could 
            release from your home with a Lifetime Mortgage. No contact details are required.`,

};

// for new-leaf
const titlesLoanToValue: ILevelTitles = {
    titles: [
        { title: 'Minimum Loan', subtitle: 'facility*', hint: '' },
        { title: 'Maximum Loan', subtitle: 'for a healthy client', hint: '' },
        {
            title: 'Maximum Loan',
            subtitle: 'for an unhealthy client',
            hint: `It is possible to reach much higher LTVs based on the medical background of the client.
                A set of simple medical and lifestyle related questions are used to work out an 
                enhanced LTV rate. Actual rates will vary according to the specific medical 
                underwriting of each client.`
        }
    ],
    ageTitle: 'Age (youngest if couple)',
    valueTitle: 'Value of Home',
    header: 'Loan-to-value calculator',
    detailsTitle: 'Client Details',
    intro: '',

};

// RELEASE-MY-EQUITY
// https://xd.adobe.com/view/78fe7614-09db-4e74-b4f9-b8774c9223fe-2dad/

// MDILANDS: Equity release midlands LTV white label
// https://xd.adobe.com/view/68e65725-9fee-41ca-af14-c201991386a4-3db5/

// Retirement Solutions partial repayments tool and LTV tool white labels
// MORE2LIFE RETIREMENT-SOLUTIONS
// https://xd.adobe.com/view/cc662de2-fd48-4410-8acd-755730d6b712-9935/

// (existing) KEYPARTNERSHIPS/ LATER LIVING NOW
// https://xd.adobe.com/view/5691d813-9dd1-491d-94f4-02829e3c9321-03c2/

// (existing) NewLeaf LTV
export const getParameterByName = (name: string, url = window.location.href): any => {
    name = name.replace(/[[]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const selected = process.env.REACT_APP_COMPANY || getParameterByName("company");

export const config = (): ILtvConfig => {
    // default
    const d = {
        name: selected,
        theme: 'default',
        poweredBy: '',
        headerColor: "#4c4c4c",
        sliderColor: "rgb(255, 221, 3)",
        border: "0px transparent solid",
        titles: titlesLoanToValue
    }

    if (selected === 'ltvlaterlivingnow') {
        d.titles = titlesEquityRelease
        d.theme = 'laterlivingnow'
    }
    if (selected === 'midlands') {
        d.theme = 'lightblue'
        d.titles = titlesEquityRelease
    }
    if (selected === 'releasemyequity') {
        d.theme = 'magenta'
        d.titles = titlesEquityRelease
    }
    if (selected === 'retirementsolutions') {
        d.theme = 'cyanmagenta'
        d.titles.titles[1].title = 'Average Loan'
        d.titles.titles[1].subtitle = 'on standard terms'
        d.titles.titles[2].subtitle = 'on standard terms'
        d.titles.titles[2].hint = ''
    }
    if (selected === 'mortgagerequired') {
        d.theme = 'purplegreen'
        d.poweredBy = 'more2life'
        d.titles.detailsTitle = 'Your Details'
        d.titles.ageTitle = 'Your Age (youngest if you’re a couple)'
        d.titles.valueTitle = 'Value of Your Home'
        d.titles.titles[1].title = 'Average Loan'
        d.titles.titles[1].subtitle = 'on standard terms'
        d.titles.titles[2].subtitle = 'on standard terms'
        d.titles.titles[2].hint = '';
        // d.titles.titles[2].hint = `It is possible to reach a much higher Loan to value (LTV)
        //     of your home based on your medical condition.
        //     A set of simple medical and lifestyle related questions are used to work out an
        //     enhanced LTV rate. Actual rates will vary according to the specific medical
        //     underwriting of each client.`
    }
    return d
}
