const newEvent = (name: string): any => {
  if (typeof(Event) === 'function') return new Event(name);
  // ie11 - compatibility
  const event = document.createEvent('Event');
  event.initEvent(name, true, true);
  return event;
};

const triggerResize = (page = '') => {
  if (typeof window !== 'undefined') {
    const event = newEvent('resize');
    window.dispatchEvent(event);
  }
};

export { triggerResize };