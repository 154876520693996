import React from 'react';
import './App.css';
import { LtvCalculatorComponent } from './../components/LtvCalculator/LtvCalculatorComponent';
import { config, getParameterByName } from './../config/config';

const App = () => {
  const inFrame = !!getParameterByName('iframe');
  return (
    <LtvCalculatorComponent config={config()} inFrame={inFrame} />
  );
}

export default App;
