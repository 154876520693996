import React from 'react';
import styled from 'styled-components';

const Wrap = styled.span`
  position: relative;
  .tip {
     display: none;
     width: 500px;

     position: absolute; top: 0px; left: 0px;
     z-index: 1000;
     background: white;
     padding: 20px 30px;
     white-space: normal;
     line-height: 1.5em;
     color: #8c8888;
     box-shadow: 2px 2px 10px #888;

     font-family: Robote, arial;
     font-size: 16px;
     font-weight: normal;
  }
  &:hover .tip { display: block; }

  @media(max-width: 620px) {
    .tip {
      width: 260px;
      left: -75px;
      top: 30px;
    }
  }
`;

export class InfoTip extends React.Component {

  render(){
    const { value } = this.props;
    return (
      <Wrap>
        &nbsp;
        <svg style={{ width: 14, height: 14 }} viewBox="0 0 100 100">
          <path fill="#000" d="M50.433,0.892c-27.119,0-49.102,21.983-49.102,49.102s21.983,49.103,49.102,49.103s49.101-21.984,49.101-49.103S77.552,0.892,50.433,0.892z M59,79.031C59,83.433,55.194,87,50.5,87S42,83.433,42,79.031V42.469c0-4.401,3.806-7.969,8.5-7.969s8.5,3.568,8.5,7.969V79.031z M50.433,31.214c-5.048,0-9.141-4.092-9.141-9.142c0-5.049,4.092-9.141,9.141-9.141c5.05,0,9.142,4.092,9.142,9.141C59.574,27.122,55.482,31.214,50.433,31.214z"/>
        </svg>
        <div className="tip">{value}</div>
      </Wrap>
    );
  }

}
