import React from 'react';
import styled from 'styled-components';

const InfoIconWrap = styled.div`
    position: relative;
    display: inline-block;
    top: 5px;
    left: 5px;
    svg { width: 23px; height: 22px; }
`;

export const InfoIcon = () => {
    // const color = "#6c7286";
    // const size = 20;
    return (
      <InfoIconWrap>
        <svg className="icon-info" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path className="main" d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z" />
          <path d="M469.333333 469.333333h85.333334v234.666667h-85.333334z" fill="#FFFFFF" />
          <path d="M512 352m-53.333333 0a53.333333 53.333333 0 1 0 106.666666 0 53.333333 53.333333 0 1 0-106.666666 0Z" fill="#FFFFFF" />
        </svg>
      </InfoIconWrap>
    );
}
